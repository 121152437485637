import React from 'react'
import PropTypes from 'prop-types'
import styles from './Section1.module.scss'
import { Fade, Reveal } from 'react-reveal'
import { Link } from '@reach/router'

const Section1 = props => {
  const { className, children, id, data, language } = props
  return (<div id={id} className={[className, styles.container, 'container'].join(' ')}>
    <div className={['row text-center', styles.centered].join(' ')}>
      <div className='col-md-12'>
        <Fade>
          {children}
        </Fade>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <Reveal>
              <ul className="list-group">
                { data.items.map((item, index) => (
                  <li className="list-group-item" key={index}>
                    <Link to={`/${language}${item}`}>{item}</Link>
                  </li>
                ))}
              </ul>
            </Reveal>
          </div>
        </div>
      </div>
    </div>
  </div>)
}

Section1.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
  title: PropTypes.any,
  id: PropTypes.string,
  data: PropTypes.object,
  language: PropTypes.string
}

export default Section1
