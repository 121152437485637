import React from 'react'
import PropTypes from 'prop-types'
import Layout from '@layouts/Layout'
import { Section1 } from '@templates/sitemap'
import Jumbotron from '@components/jumbotron/Jumbotron'
import { faSitemap } from '@fortawesome/free-solid-svg-icons'
import { graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

export const query = graphql`query QueryPages {
  page: allSitePage(filter: {context: {intl: {originalPath: {regex: "/^((?!dev-|.html).)*$/s"}}}}, sort: {fields: context___intl___language}) {
    items: distinct(field: context___intl___originalPath)
  }
}`

const Sitemap = props => {
  const { formatMessage } = useIntl()
  const { pageContext, data: queryData } = props
  const { languageName, language } = pageContext
  return <Layout languageName={languageName} pageContext={pageContext}>
    <Jumbotron
      icon={faSitemap}
      title={formatMessage({ id: 'sitemap' })}
      description=''
    />
    <div className='row'>
      <Section1 className='col-md-12' data={queryData.page} language={language}>
      </Section1>
    </div>
  </Layout>
}

Sitemap.propTypes = {
  pageContext: PropTypes.any,
  data: PropTypes.any
}

export default Sitemap
